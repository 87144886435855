import uniq from 'lodash.uniq';
import shuffle from 'lodash.shuffle';
import qs from 'query-string';
import { PLAN_LEVELS } from './constants';

export function scrollToID({ id, offset = 0, behavior = 'auto' }) {
  try {
    setTimeout(() => {
      const elem = document.getElementById(id);
      window.scrollTo({
        behavior,
        top:
          elem.getBoundingClientRect().top +
          document.documentElement.scrollTop -
          offset,
      });
    });
  } catch (e) {
    console.log(e);
  }
}

export function filterDelivery({ item, deliveryFilter }) {
  const itemLocations = item?.brewery?.allShippingLocations;
  if (!itemLocations || !deliveryFilter) {
    return true;
  }

  return (
    itemLocations.includes('Ontario') || itemLocations.includes(deliveryFilter)
  );
}

export function getBreweryLocations(filteredBeers) {
  return uniq(
    filteredBeers.reduce((acc, item) => {
      return [...acc, ...item?.brewery?.breweryLocations];
    }, [])
  );
}

export function getFilteredBreweries(items) {
  return Object.keys(
    items.reduce((acc, item) => {
      return {
        ...acc,
        [item.brewery.name]: true,
      };
    }, {})
  );
}

function trackGAEvent(payload) {
  if (typeof window === 'undefined' || !window.gtag) return;

  window.gtag('event', payload.action, {
    send_to: process.env.GATSBY_GOOGLE_ANALYTICS_ID,
    event_category: payload.category,
    event_label: payload.label,
    ...(payload.value && { value: payload.value }),
  });
}

export function trackEvent(payload) {
  if (
    process.env.NODE_ENV !== 'production' ||
    process.env.GATSBY_APP_ENV === 'dev'
  ) {
    console.log({ action: payload });
    return;
  }

  if (typeof window === 'undefined' || !window.gtag) return;
  try {
    trackGAEvent(payload);
  } catch (e) {
    console.log(e);
  }
}

export const storageItemExists = item =>
  typeof window !== 'undefined' && localStorage.getItem(item) !== null;
export const getStorageItem = item =>
  typeof window !== 'undefined' && localStorage.getItem(item);
export const setStorageItem = (item, value) =>
  typeof window !== 'undefined' && localStorage.setItem(item, value);
export const removeStorageItem = item =>
  typeof window !== 'undefined' && localStorage.removeItem(item);

const replaceLeadingTheRegex = /^(The )/i;

export function sortAlphabeticallyExceptThe(a, b) {
  const nameA = a.replace(replaceLeadingTheRegex, '');
  const nameB = b.replace(replaceLeadingTheRegex, '');

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function getParamFromUrl(paramName) {
  if (typeof window === 'undefined' || !window?.location?.search) return;

  const params = new URLSearchParams(window?.location?.search);
  return params.get(paramName);
}

export function getSearchTermFromURL() {
  return getParamFromUrl('search_term');
}

function setQueryStringValue(key, value, queryString = window.location.search) {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
}

const setQueryStringWithoutPageReload = qsValue => {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;
  window.history.pushState({ path: newurl }, '', newurl);
};

export function setActualURLSearchTerm(term) {
  setQueryStringValue('search_term', term);
}

export function sortAndMaybeShuffleBeers(beers, shouldShuffle = true) {
  const shuffleFn = shouldShuffle ? shuffle : e => e;
  return [
    ...shuffleFn(
      beers.filter(item => item?.brewery?.planLevel === PLAN_LEVELS.PREMIUM)
    ),
    ...shuffleFn(
      beers.filter(item => item?.brewery?.planLevel === PLAN_LEVELS.STANDARD)
    ),
    ...shuffleFn(
      beers.filter(item => item?.brewery?.planLevel === PLAN_LEVELS.BASIC)
    ),
    ...shuffleFn(
      beers.filter(item => item?.brewery?.planLevel === PLAN_LEVELS.BASIC_FREE)
    ),
    ...shuffleFn(
      beers.filter(item => item?.brewery?.planLevel === PLAN_LEVELS.FREE)
    ),
  ];
}
