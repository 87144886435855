/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';
import { SignupFormProvider } from './src/contexts/signup-form';
import smoothscroll from 'smoothscroll-polyfill';

export const onClientEntry = () => {
  smoothscroll.polyfill();
};

// You can delete this file if you're not using it
export const wrapRootElement = ({ element }) => (
  <SignupFormProvider>{element}</SignupFormProvider>
);
