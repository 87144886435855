import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { trackEvent, setStorageItem } from '../lib';
import { Text, Stack, P } from '../components/system';
import { SignupFormContext } from '../contexts/signup-form';

import { breakpoint } from '../constants';

import addToMailchimp from 'gatsby-plugin-mailchimp';
import camHead from '../images/cam-head.png';
import styled from 'styled-components';

export const EXITED_EMAIL_FORM_KEY = 'BF-no-email-please';
export const SUCCESS_EMAIL_KEY = 'BF-email-added';

export default function SignupForm() {
  const { setIsOpen, isOpen } = useContext(SignupFormContext);
  const [email, setEmail] = useState('');
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const inputRef = useRef();
  const closeRef = useRef();

  const onExit = useCallback(() => {
    setStorageItem(EXITED_EMAIL_FORM_KEY, 'yes');
    setIsOpen(false);
    setError();
    setSuccess(false);
    trackEvent({
      action: 'Exit Sign up',
      category: 'Registration',
      label: success ? 'success' : 'leave me alone',
    });
  }, [setIsOpen, success]);

  useEffect(() => {
    if (isOpen && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    function escapeModal(e) {
      e.keyCode === 27 && onExit();
    }

    document.addEventListener('keydown', escapeModal);

    return () => document.removeEventListener('keydown', escapeModal);
  }, [onExit, setIsOpen]);

  const onSuccessfulSubmit = useCallback(() => {
    setSuccess(true);
    setStorageItem(SUCCESS_EMAIL_KEY, 'yes');
    trackEvent({
      action: 'Successfully Signed Up',
      category: 'Registration',
      label: true,
    });
    closeRef.current.focus();
  }, []);

  const addUser = useCallback(
    async e => {
      e.preventDefault();
      try {
        const result = await addToMailchimp(email);
        if (result.result === 'error') {
          setError('Sorry, there was an issue. Please try again!');
          trackEvent({
            action: 'Sign Up Error',
            category: 'Registration',
            label: result.msg,
          });
        }

        if (result.result === 'success') {
          onSuccessfulSubmit();
        }

        return result;
      } catch (e) {
        console.log(e);
      }
    },
    [email, onSuccessfulSubmit]
  );

  return (
    <>
      {isOpen && (
        <SignUpContainer tabindex="-1">
          <Stack>
            <div
              css={`
                text-align: right;
              `}
            >
              <button
                tabIndex={3}
                onClick={onExit}
                ref={closeRef}
                css={`
                  background: none;
                  border: none;
                  margin: 0;
                  padding: 20px;
                  margin: -20px;
                `}
              >
                <svg
                  css={`
                    fill: ${p => p.theme.colors.primary};
                  `}
                  id="Capa_1"
                  enable-background="new 0 0 413.348 413.348"
                  height="20"
                  viewBox="0 0 413.348 413.348"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
                </svg>
              </button>
            </div>
            <div
              css={`
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                @media (min-width: ${breakpoint.sm}) {
                  border: none;
                }
              `}
            >
              <img
                css={`
                  width: 50px;
                  border-radius: 50%;
                  margin-right: 20px;
                  border: 2px solid ${p => p.theme.colors.primaryLight};
                  @media (min-width: ${breakpoint.sm}) {
                    border: 3px solid ${p => p.theme.colors.primaryLight};
                    width: 75px;
                  }
                `}
                src={camHead}
                alt="cam"
              />

              <P>
                👋 Hey! Cam here, creator of Beer Finder. I hope you're finding
                some good&nbsp;beers!
              </P>
            </div>

            {!!success && (
              <div>
                <Text>
                  <strong>Thanks! Now get back to beer hunting 🍻</strong>
                </Text>
              </div>
            )}
            {!success && (
              <>
                <P>
                  If you're interested in hearing about new releases from your
                  favourite breweries, enter your email below and we will add
                  you to the Beer List.&nbsp;🍻
                </P>
                <form
                  css={`
                    width: 100%;
                    display: flex;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
                  `}
                  onSubmit={addUser}
                >
                  <input
                    tabIndex={1}
                    ref={inputRef}
                    css={`
                    padding: 10px 10px;
                    flex: 1;
                    width: 100%;
                    /* border: 1px solid ${p => p.theme.colors.greyDark}; */
                    border: none;
                    /* border-bottom: 2px solid ${p =>
                      p.theme.colors.primaryLight}; */
                  `}
                    placeholder="email@example.com"
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                  <button
                    tabIndex={2}
                    css={`
                    background-color: ${p => p.theme.colors.primary};
                    color: white;
                    border: none;
                    padding: 16px;
                    /* border-bottom: 2px solid ${p =>
                      p.theme.colors.primaryLight}; */
                  `}
                    type="submit"
                  >
                    >
                  </button>
                </form>

                {!!error && (
                  <P
                    css={`
                      color: red;
                    `}
                  >
                    <div dangerouslySetInnerHTML={{ __html: error }}></div>
                  </P>
                )}
                <P
                  css={`
                    color: ${p => p.theme.colors.greyDarker};
                  `}
                >
                  It's free and you can unsubscribe anytime.
                </P>
              </>
            )}
          </Stack>
        </SignUpContainer>
      )}
    </>
  );
}

const SignUpContainer = styled.div`
  position: fixed;
  background: white;
  padding: 20px 20px 40px;

  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  border-radius: 0;
  border-top: 6px solid ${p => p.theme.colors.primary};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  min-height: 425px;
  animation: fadein 0.4s;

  @keyframes fadein {
    from {
      opacity: 0.5;
      transform: translateY(700px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (min-width: ${breakpoint.sm}) {
    padding: 40px 40px;
    top: initial;
    left: initial;
    right: 10%;
    bottom: 0;
    max-width: 500px;
    border-top: 6px solid ${p => p.theme.colors.primary};
  }
`;
