// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-location-template-js": () => import("./../src/templates/LocationTemplate.js" /* webpackChunkName: "component---src-templates-location-template-js" */),
  "component---src-components-layout-post-js": () => import("./../src/components/layout-post.js" /* webpackChunkName: "component---src-components-layout-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-successful-payment-js": () => import("./../src/pages/successful-payment.js" /* webpackChunkName: "component---src-pages-successful-payment-js" */)
}

