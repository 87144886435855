import styled, { css } from 'styled-components';
import {
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
} from '@material-ui/system';

import { breakpoint } from '../constants';

export const hideMobile = css`
  ${props =>
    props.hideMobile &&
    `
    display: none;
    @media (min-width: ${breakpoint.sm}) {
      display: initial;
    }
  `}
`;

export const typeSizeMap = {
  xs: 0.7,
  sm: 0.8,
  md: 1,
  lg: 1.5,
  xl: 2.5,
  xxl: 8.5,
};

function arrifyItem(item) {
  if (Array.isArray(item)) {
    return item;
  }
  return [item];
}

const sizesMixin = lineHeightMultiplier => css`
  ${({ size, ...restProps }) => {
    const shortHand = Object.keys(typeSizeMap).find(key => restProps[key]);

    const sizes = arrifyItem(size).map(size =>
      typeof size === 'number' ? size : typeSizeMap[shortHand || size || 'md']
    );

    const breakpointArr = Object.keys(breakpoint);

    const str = sizes.reduce((acc, currentSize, index) => {
      const x = `
        font-size: ${currentSize}rem;
        line-height: ${currentSize * lineHeightMultiplier}rem;
        `;

      if (index === 0) {
        return x;
      }

      return `
      ${acc}

      @media(min-width: ${breakpoint[breakpointArr[index]]}) {
        ${x}
      }
      `;
    }, '');

    return str;
  }}
`;

export const Text = styled.span`
  ${sizesMixin(1.4)};

  color: ${p =>
    p.theme.colors[p.textColor] || p.textColor || p.theme.colors.black};
  ${hideMobile}
`;

export const P = styled(Text).attrs(({ as = 'p' }) => ({ as }))``;
P.defaultProps = {
  as: 'p',
};

export const H = styled.h2`
  ${sizesMixin(1)};

  color: ${p =>
    p.theme.colors[p.textColor] || p.textColor || p.theme.colors.black};
  ${hideMobile}
`;

H.defaultProps = {
  as: 'h2',
};

const clusterSizeMap = {
  xs: 1,
  sm: 2,
  md: 4,
  lg: 8,
  xl: 40,
};

export const Cluster = styled.div.attrs(props => {
  const shortHand = Object.keys(clusterSizeMap).find(key => props[key]);
  const size = Number.isInteger(props.size)
    ? props.size
    : clusterSizeMap[shortHand || props.size || 'md'];
  return {
    size,
  };
})`
  --margin: ${props => props.size}px;
  --margin-desktop: calc(${props => props.size}px * 2);
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--margin));

  > * {
    margin: var(--margin);
  }

  @media (min-width: ${breakpoint.sm}) {
    margin: calc(-1 * var(--margin-desktop));

    > * {
      margin: var(--margin-desktop);
    }
  }
`;

const activePillStyles = css`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};

  :hover {
    background: ${props => props.theme.colors.primary};
  }
  /* color: white; */
`;

const pillStyles = css`
  padding: 2px 8px;
  position: relative;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.greyDark};
  text-align: left;

  border-right: 4px solid ${props => props.theme.colors.black}6E;
  border-bottom: 3px solid ${props => props.theme.colors.black}6E;
  :hover,
  :focus {
    border: 1px solid ${props => props.theme.colors.greyDark};
    border-right: 4px solid ${props => props.theme.colors.contrast};
    border-bottom: 3px solid ${props => props.theme.colors.contrast};
  }
  :active {
    ${activePillStyles};
    transform: translateY(2px) translateX(2px);
  }
  border-radius: 4px;
  ${p => !!p.selected && activePillStyles};
`;

const underlineStyles = css`
  text-align: left;
  border: none;
  border-bottom: 4px solid transparent;
  background: ${props => props.theme.colors.white};
  border-bottom: 4px solid ${props => props.theme.colors.greyDark};
  :hover,
  :focus {
    border-bottom: 4px solid ${props => props.theme.colors.contrast};
  }
`;

export const Button = styled.button`
  transition: border 0.2s, background 0.1s;
  text-decoration: none;
  outline-offset: 4px;
  :focus {
    outline: 2px dashed ${props => props.theme.colors.primary};
    outline-offset: 4px;
  }
  color: ${p => p.theme.colors.primary};
  background: ${props =>
    props.primary ? props.theme.colors.primary : 'inherit'};
  ${props => props.variant === 'pill' && pillStyles}
  ${props => props.variant === 'underline' && underlineStyles}
`;
Button.defaultProps = {
  variant: 'underline',
};

export const Box = styled.div`
    ${borders}
    ${display}
    ${flexbox}
    ${palette}
    ${positions}
    ${shadows}
    ${sizing}
    ${spacing}
    ${typography}
  `;

export const Center = styled.div`
  > * {
    margin-left: auto;
    margin-right: auto;
  }
`;

const sizeMap = {
  xs: 0.2,
  sm: 0.7,
  md: 1.5,
  lg: 2.5,
  xl: 4,
};

export const Stack = styled.div.attrs(props => {
  const shortHand = Object.keys(sizeMap).find(key => props[key]);
  const size = Number.isInteger(props.size)
    ? props.size
    : sizeMap[shortHand || props.size || 'md'];
  return {
    size,
  };
})`
  --space: ${props => props.size}rem;

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: var(--space);
  }
`;

export const FadeIn = styled.div`
  animation: fadein ${p => p.fadeIn || 0.3}s;

  @keyframes fadein {
    from {
      opacity: 0;

      ${p => p.translateY && 'transform: translateY(10px)'};
    }
    to {
      opacity: 1;

      ${p => p.translateY && 'transform: translateY(0)'};
    }
  }
`;
FadeIn.defaultProps = {
  translateY: true,
};
