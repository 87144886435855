exports.beerStyles = [
  'ale',
  'ipa',
  'pale ale',
  'sour',
  'stout',
  'lager',
  'wheat',
  'belgian',
  'saison',
  'brown',
  'pilsner',
  'porter',
  'blonde',
  'english',
  'gose',
  'brut',
  'kolsch',
];

exports.beerCharacteristics = [
  'fruit',
  'light',
  'dry',
  'citrus',
  'sweet',
  'dark',
  'chocolate',
  'barrel',
  'caramel',
  'tart',
  'easy',
  'hazy',
  'coffee',
  'hoppy',
  'vanilla',
  'dry-hopped',
  'pineapple',
  'mango',
  'cherry',
  'lactose',
  'malty',
  'nutty',
  'milkshake',
];

exports.breakpoint = {
  xs: '400px',
  sm: '768px',
  md: '900px',
  lg: '1200px',
};

exports.EVENT_COUNT_POPUP_THRESHOLD = 3;

exports.NO_LOCATION_KEY = 'none';

// note: duplicated in backend
exports.PLAN_LEVELS = {
  BASIC_FREE: 0,
  FREE: 1,
  BASIC: 2,
  STANDARD: 3,
  PREMIUM: 4,
};
