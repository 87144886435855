import React, { useState, createContext, useEffect } from 'react';
import {
  EXITED_EMAIL_FORM_KEY,
  SUCCESS_EMAIL_KEY,
} from '../components/SignupForm';
import { getStorageItem } from '../lib';

function getCanPopup() {
  const hasExitedEmail = !!getStorageItem(EXITED_EMAIL_FORM_KEY);
  const hasSignedUp = !!getStorageItem(SUCCESS_EMAIL_KEY);
  return !hasExitedEmail && !hasSignedUp;
}

export const SignupFormContext = createContext({});

export function SignupFormProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAllowedToPopUp, setIsAllowedToPopUp] = useState(getCanPopup());

  useEffect(() => {
    setIsAllowedToPopUp(getCanPopup());
  }, [isOpen]);

  return (
    <SignupFormContext.Provider value={{ isOpen, setIsOpen, isAllowedToPopUp }}>
      {children}
    </SignupFormContext.Provider>
  );
}
